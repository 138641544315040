var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.dados)?_c('div',{staticClass:"row",attrs:{"id":_vm.printDiv}},[(_vm.dados.deleted_at && _vm.dados.deleted_at!='')?_c('div',{staticClass:"col-12 p-3"},[_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('b-icon-exclamation-triangle-fill'),_vm._v(" BAIXA EXCLUIDA "+_vm._s(_vm._f("moment")(_vm.dados.deleted_at,"DD/MM/YYYY HH:mm:ss"))+" - Usuário: "+_vm._s(_vm.dados.UsuarioEstorno)+" ")],1)],1):_vm._e(),_c('div',{staticClass:"col-2 pr-sm-1"},[_c('b-card',{staticClass:"text-center",attrs:{"header":"Baixa Nº","header-class":"text-white font-weight-bold","header-bg-variant":"info","border-variant":"info"}},[_c('h2',[_vm._v(_vm._s(_vm.dados.id))])])],1),_c('div',{staticClass:"col-6 pr-sm-1 pl-sm-1"},[_c('b-card',{staticClass:"text-center",attrs:{"header":"Conta / Carteira","header-class":"text-white font-weight-bold","header-bg-variant":"info","border-variant":"info","body-class":"text-left pt-2"}},[_c('b',[_c('i',{staticClass:"fa fa-wallet"}),_vm._v(" "+_vm._s(_vm.dados.Conta))]),_c('hr',{staticClass:"mt-1 mb-1"}),_c('b',[_c('i',{staticClass:"fa fa-tags"}),_vm._v(" "+_vm._s(_vm.dados.Historico))]),_c('hr',{staticClass:"mt-1 mb-1"}),_c('b',[_c('i',{staticClass:"fas fa-funnel-dollar"}),_vm._v(" "+_vm._s(_vm.dados.PlanoContas))])])],1),_c('div',{staticClass:"col- pl-sm-1"},[_c('b-card',{staticClass:"text-center",attrs:{"header":"Total Baixa","header-class":"text-white font-weight-bold","header-bg-variant":"info","border-variant":"info"}},[_c('h3',[_vm._v(_vm._s(_vm._f("currencyMask")(_vm.dados.valor)))])])],1),_vm._m(0),_c('div',{staticClass:"col-12"},[_c('h3',[_c('b-icon-list'),_vm._v(" Parcelas")],1),_c('b-table',{attrs:{"items":_vm.dados.itens,"fields":[
          {
            key: 'contas_pagar_id',
            label: 'Cod. Contas Pagar',
            class: 'text-center',
          },
          { key: 'parcela', label: 'Parcela', class: 'text-center' },
          { key: 'fornecedor', label: 'Fornecedor', class: 'text-center' },
          { key: 'obs', label: 'Observações', class: 'text-center' },
          { key: 'dt_vencimento', label: 'Vencimento', class: 'text-center' },
          {
            key: 'valor_parcela',
            label: 'Valor Parcela',
            class: 'text-center',
          },
          { key: 'valor', label: 'Pago', class: 'text-center' },
          { key: 'banco_movimento_id', label: 'Bancário' },
        ]},scopedSlots:_vm._u([{key:"cell(parcela)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.parcela)+" /"+_vm._s(row.item.parcelas)+" ")]}},{key:"cell(dt_vencimento)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("moment")(row.item.dt_vencimento,"DD/MM/YYYY"))+" ")]}},{key:"cell(valor_parcela)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("currencyMask")(row.item.valor_parcela))+" ")]}},{key:"cell(valor)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("currencyMask")(row.item.valor))+" ")]}},{key:"cell(banco_movimento_id)",fn:function(row){return [_c('b-btn',{attrs:{"variant":"none","icon":""},on:{"click":function($event){return _vm.openMovimento(row.item.banco_movimento_id)}}},[_c('b-icon-search')],1)]}}],null,false,3028954996)}),_c('hr')],1),_c('div',{staticClass:"col-12"},[_vm._m(1),_c('FileUpload',{attrs:{"value":_vm.dados.anexos,"enableEdit":false,"enableTitle":false}})],1)]):_vm._e(),_c('div',{staticClass:"col-12"},[(!_vm.dados.deleted_at || _vm.dados.deleted_at == '')?_c('b-btn',{attrs:{"size":"lg","variant":"danger"},on:{"click":function($event){return _vm.excluir()}}},[_c('b-icon-trash'),_vm._v(" Excluir Baixa")],1):_vm._e(),_c('b-btn',{staticClass:"ml-2",attrs:{"size":"lg","variant":"info"},on:{"click":function($event){return _vm.imprimir()}}},[_c('b-icon-printer'),_vm._v(" Imprimir")],1)],1),_c('b-modal',{attrs:{"id":"modal-edit-movimentacao","title":"Movimentação","hide-footer":""}},[_c('movimento-form',{attrs:{"itemEdit":{ id: _vm.openMovimentacaoID }},on:{"cancelar":_vm.closeMovimento}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('i',{staticClass:"fa fa-file-pdf"}),_vm._v(" Anexos")])
}]

export { render, staticRenderFns }