import { render, staticRenderFns } from "./TransferenciaForm.vue?vue&type=template&id=2bb0e476&scoped=true"
import script from "./TransferenciaForm.vue?vue&type=script&lang=js"
export * from "./TransferenciaForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bb0e476",
  null
  
)

export default component.exports