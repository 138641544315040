<template>
  <div>
   
    <div class="row">
      <div class="col-12" v-if="carteira">
        <label><i class="fa fa-wallet" /> Origem</label>
        <b-input disabled v-model="carteira.nome" />
      </div>
      
      <div class="col-12 mt-3">
        <lable><i class="fa fa-wallet" /> Destino</lable>
        <v-select
          v-model="obj.destino"
          :options="listaCarteiras"
          label="nome"
        :clearable="true"

        ></v-select>
      </div>
      <div class="col-12 col-sm-6 mt-3">
        <label>Valor</label>
        <b-input v-model="obj.valor" v-money="moneyMask" />
      </div>
      <div class="col-12 col-sm-6 mt-3">
        <b-form-group label="Data">
          <v-date-picker v-model="obj.data">
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </b-form-group>
      </div>
      <div class="col-12 mt-4 text-right">
        <hr />
        <b-btn variant="success" @click="salvar"
          ><i class="far fa-save" /> Salvar</b-btn
        >
        <b-btn variant="danger" class="ml-2" @click="$emit('cancelar')"
          ><b-icon-x-circle /> Cancelar</b-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import CarteiraLib from "../../../libs/Carteiras";
import BancoMovimentoLib from "../../../libs/BancoMovimentoLib";
export default {
  components: {},
  props: {
    carteiraId: Number
  },
  mounted() {
    this.carregarCarteira();
  },
  data() {
    return {
      carteira: null,
      listaCarteiras: [],
      obj: {
        destino: null,
        origem: null,
        valor: 0,
        data: new Date()
      },
      idHistoricoTransferencia: {
        origem: 4,
        destino: 3
      }
    };
  },
  watch: {},
  computed: {},
  methods: {
    async carregarCarteira() {
      this.carteira = (
        await CarteiraLib.getCareteiras({ id: this.carteiraId })
      ).data[0];
      if (this.carteira) {
        this.obj.origem = this.carteira;
      }
      this.listaCarteiras = (await CarteiraLib.getCareteiras()).data;
    },
    async salvar() {
      let err = [];
      if (!this.obj.destino || this.obj.destino.id == this.obj.origem.id) {
        err.push("Informe a carteira/conta de destino válida!");
      }
     /* if (
        !this.obj.data ||
        this.formatValorReal(this.obj.valor) <= 0 ||
        this.formatValorReal(this.obj.valor) > this.carteira.saldo
      ) {
        err.push("Valor informado inválido ou maior que saldo disponível!");
      }*/
      if (!this.obj.data) {
        err.push("Informe uma data válida!");
      }

      if (err.length > 0) {
        this.$swal("Atenção", err.join("<br/>"), "error");
      } else {
        let res = await BancoMovimentoLib.transferenciaMovimento({
          ...this.obj,
          ids: this.idHistoricoTransferencia
        });
        console.log("tra", res);
        if (res && res.success) {
          this.$swal({
            title: "Concluído",
            text: "Movimento salvo com sucesso!",
            icon: "success",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 500
          }).then(() => {});
          this.$emit("cancelar");
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>