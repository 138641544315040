<template>
  <div>
    <div v-if="dados" class="row" :id="printDiv">
      <div class="col-12 p-3" v-if="dados.deleted_at && dados.deleted_at!=''">
          <b-alert variant="danger" show="">
           <b-icon-exclamation-triangle-fill /> BAIXA EXCLUIDA {{dados.deleted_at | moment("DD/MM/YYYY HH:mm:ss")}} - 
            Usuário: {{dados.UsuarioEstorno}}
          </b-alert>
      </div>
      <div class="col-2 pr-sm-1">
        <b-card
          class="text-center"
          header="Baixa Nº"
          header-class="text-white font-weight-bold"
          header-bg-variant="info"
          border-variant="info"
        >
          <h2>{{ dados.id }}</h2>
        </b-card>
      </div>
      <div class="col-6 pr-sm-1 pl-sm-1">
        <b-card
          class="text-center"
          header="Conta / Carteira"
          header-class="text-white font-weight-bold"
          header-bg-variant="info"
          border-variant="info"
          body-class="text-left pt-2"
        >
          <b><i class="fa fa-wallet" /> {{ dados.Conta }}</b>
          <hr class="mt-1 mb-1" />
          <b><i class="fa fa-tags" /> {{ dados.Historico }}</b>
            <hr class="mt-1 mb-1" />
          <b><i class="fas fa-funnel-dollar" /> {{ dados.PlanoContas }}</b>
        </b-card>
      </div>
      <div class="col- pl-sm-1">
        <b-card
          class="text-center"
          header="Total Baixa"
          header-class="text-white font-weight-bold"
          header-bg-variant="info"
          border-variant="info"
        >
          <h3>{{ dados.valor | currencyMask }}</h3>
        </b-card>
      </div>
      <div class="col-12">
        <hr />
      </div>
      <div class="col-12">
        <h3><b-icon-list /> Parcelas</h3>
        
        <b-table
          :items="dados.itens"
          :fields="[
            {
              key: 'contas_pagar_id',
              label: 'Cod. Contas Pagar',
              class: 'text-center',
            },
            { key: 'parcela', label: 'Parcela', class: 'text-center' },
            { key: 'fornecedor', label: 'Fornecedor', class: 'text-center' },
            { key: 'obs', label: 'Observações', class: 'text-center' },
            { key: 'dt_vencimento', label: 'Vencimento', class: 'text-center' },
            {
              key: 'valor_parcela',
              label: 'Valor Parcela',
              class: 'text-center',
            },
            { key: 'valor', label: 'Pago', class: 'text-center' },
            { key: 'banco_movimento_id', label: 'Bancário' },
          ]"
        >
          <template #cell(parcela)="row">
            {{ row.item.parcela }} /{{ row.item.parcelas }}
          </template>
          <template #cell(dt_vencimento)="row">
            {{ row.item.dt_vencimento | moment("DD/MM/YYYY") }}
          </template>
          <template #cell(valor_parcela)="row">
            {{ row.item.valor_parcela | currencyMask }}
          </template>
          <template #cell(valor)="row">
            {{ row.item.valor | currencyMask }}
          </template>
          <template #cell(banco_movimento_id)="row">
            <b-btn
              variant="none"
              icon
              @click="openMovimento(row.item.banco_movimento_id)"
              ><b-icon-search
            /></b-btn>
          </template>
        </b-table>
        <hr />
      </div>
      <div class="col-12">
        <h4><i class="fa fa-file-pdf" /> Anexos</h4>
        <FileUpload
          :value="dados.anexos"
          :enableEdit="false"
          :enableTitle="false"
        />
      </div>
    </div>
    <div class="col-12">
      <b-btn size="lg" variant="danger" @click="excluir()"
      v-if="!dados.deleted_at || dados.deleted_at == ''"
        ><b-icon-trash /> Excluir Baixa</b-btn
      >

      <b-btn size="lg" variant="info" class="ml-2" @click="imprimir()"
        ><b-icon-printer /> Imprimir</b-btn
      >
    </div>
    <b-modal id="modal-edit-movimentacao" title="Movimentação" hide-footer>
      <movimento-form
        :itemEdit="{ id: openMovimentacaoID }"
        @cancelar="closeMovimento"
      />
    </b-modal>
  </div>
</template>

<script>
import ContasPagarLib from "../../../libs/ContasPagarLib";
import PrinterWindowHelper from "../../../utils/PrinterWindowHelper";
import FileUpload from "../../common/FileUpload.vue";
import MovimentoForm from "../Bancario/MovimentoForm.vue";
export default {
  components: {
    FileUpload,
    MovimentoForm,
  },
  props: {
    baixaId: Number,
  },
  mounted() {
    this.carregar();
  },
  data() {
    return {
      loading: false,
      dados: null,
      openMovimentacaoID: 0,
      printDiv:"PRINTDIV"
    };
  },
  watch: {},
  computed: {},
  methods: {
   async imprimir() {
      PrinterWindowHelper(document.getElementById(this.printDiv).innerHTML, 3);
    },
    closeMovimento() {
      this.$bvModal.hide("modal-edit-movimentacao");
    },
    openMovimento(id) {
      this.openMovimentacaoID = id;
      this.$bvModal.show("modal-edit-movimentacao");
    },
    excluir() {
      this.$swal({
        title: "Tem certeza que deseja excluir esta baixa?",
        html: "<span class='text-center'>Ao excluir não será possível recupera-la. <br/><b>Atenção!<br/>Os itens na baixa inclusos ficarão em aberto e a movimentação financeira excluida!</span>",
        showCancelButton: true,
        icon: "question",
        cancelButtonText: "Não",
        confirmButtonText: "Sim, Excluir!",
        confirmButtonColor: "red",
      }).then(async (x) => {
        if (x.isConfirmed) {
          let res = await ContasPagarLib.excluirBaixaContasPagar({
            baixa_id: this.dados.id,
          });
          console.log("res", res);
          if (res) {
            this.$swal({
              title:
                res.success === true
                  ? "Excluído com sucesso!"
                  : "Erro ao excluir",
              text: res.message,
              icon: res.success?'success':'error',
            });
            this.carregar();
          }
        }
      });
    },
    async carregar() {
      this.printDiv = "PRINTDIV_" + Math.random();

      this.loading = true;

      try {
        let res = await ContasPagarLib.getBaixasContasPagar({
          id: this.baixaId,
          withFull: true,
        });
        console.log("BaixaID", res);
        if (res && res.success && res.data.length > 0) {
          this.dados = res.data[0];
        }
      } catch (error) {
        console.log("ERROR ON GET CP BAIXA", error);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
